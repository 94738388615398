import { Drawer, message } from 'antd';
import { DEFAULT_OVERLAY_WIDTH } from 'components/Overlays/constants';
import useOverlayPanelHelper from 'components/Overlays/useOverlayPanelHelper';
import UpdatePasswordForm from 'features/user/components/UpdatePasswordForm';
import { TUserInfo } from 'features/auth/types';
import { useUpdatePasswordMutation } from 'features/user/query';
import { TUserAccount } from 'features/user/types';
import React, { Dispatch, memo, useCallback, useEffect } from 'react';
import useGetAppBreakPoints from 'utils/hooks/useGetAppBreakPoints';
import { StrictUnion } from 'utils/types';

type Props = {
  open: boolean;
  setOpen: Dispatch<boolean>;
  selectedUser?: StrictUnion<TUserAccount | TUserInfo>;
};

const UpdateUserPasswordPanel = ({ open, setOpen, selectedUser }: Props) => {
  const { isLandscapeTablet } = useGetAppBreakPoints();

  const [updatePasswordFn, { isLoading, isSuccess }] =
    useUpdatePasswordMutation();

  const onCancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleUpdatePassword = useCallback(
    (newPassword: string) => {
      const { id } = selectedUser || {};
      if (!id) return;
      updatePasswordFn({ id, newPassword });
    },
    [updatePasswordFn, selectedUser]
  );

  useEffect(() => {
    if (!isSuccess) return;
    void message.success(`Cập nhật mật khẩu thành công`);
    onCancel();
  }, [isSuccess, onCancel]);

  const { overlayPanelFooter, submitBtnRef } = useOverlayPanelHelper({
    cancelBtnProps: {
      onClick: onCancel,
      disabled: isLoading,
    },
    submitBtnProps: {
      loading: isLoading,
    },
  });

  return (
    <Drawer
      open={open}
      onClose={onCancel}
      footer={overlayPanelFooter}
      width={isLandscapeTablet ? '90vw' : DEFAULT_OVERLAY_WIDTH}
      title="Đổi mật khẩu"
    >
      <UpdatePasswordForm
        open={open}
        userName={selectedUser?.userName ?? ''}
        isLoading={isLoading}
        onUpdatePasswordFn={handleUpdatePassword}
        ref={submitBtnRef}
      />
    </Drawer>
  );
};

export default memo(UpdateUserPasswordPanel);
