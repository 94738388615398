import {
  TUserInfo,
  TUserSignInPayload,
  TUserSignInSuccessResponse,
} from 'features/auth/types';
import { baseApi } from 'lib/rtkQuery/baseApi';
import { QueryResponse } from 'lib/rtkQuery/types';
import { QueryTags } from 'lib/rtkQuery/constants';

const endpoint = '/UserAuth';

export const authQueryApi = baseApi
  .enhanceEndpoints({
    addTagTypes: [QueryTags.UserDetail],
  })
  .injectEndpoints({
    endpoints: builder => ({
      SignInUser: builder.mutation<
        QueryResponse<TUserSignInSuccessResponse>,
        TUserSignInPayload
      >({
        query: payload => ({
          url: `${endpoint}/Authenticate`,
          method: 'POST',
          data: payload,
        }),
      }),

      GetCurrentUser: builder.query<QueryResponse<TUserInfo>, unknown>({
        query: () => ({
          url: `${endpoint}/Me`,
          method: 'GET',
        }),
        providesTags: [QueryTags.UserDetail],
      }),

      RevokeToken: builder.mutation<QueryResponse<string>, undefined>({
        query: () => ({
          url: `${endpoint}/Revoke`,
          method: 'POST',
          manuallyHandlingErrorMsg: true,
          disableSpinner: true,
        }),
      }),
    }),
  });

export const {
  useSignInUserMutation,
  useGetCurrentUserQuery,
  useRevokeTokenMutation,
} = authQueryApi;
