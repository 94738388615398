import appLogo from 'assets/logos/logo.png';
import favicon from 'assets/favicons/favicon.ico';

import logoHatika from 'assets/logos/logo-hatika.svg';
import faviconHatika from 'assets/favicons/favicon-hatika.ico';

export enum APP_DOMAIN {
  HATIKA = 'mgr-htk.metatrip.vn',
}

const domain = window.location.hostname as APP_DOMAIN;

const customLogo: Record<APP_DOMAIN, string> = {
  [APP_DOMAIN.HATIKA]: logoHatika,
};

const customFavicon: Record<APP_DOMAIN, string> = {
  [APP_DOMAIN.HATIKA]: faviconHatika,
};

export const getAppLogo = () => customLogo[domain] || appLogo;

export const setAppFavicon = () => {
  const link = document.createElement('link');
  const oldLink = document.getElementById('dynamic-favicon');

  link.id = 'dynamic-favicon';
  link.rel = 'icon';
  link.href = customFavicon[domain] || favicon;

  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
};
