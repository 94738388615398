import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import LockIcon from '@mui/icons-material/Lock';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { IconButton } from '@mui/material';
import { Dropdown, MenuProps, Tooltip } from 'antd';
import dummyAvatar from 'assets/imgs/dummy-avatar.jpg';
import clsx from 'clsx';
import OverlayPanelWrapper from 'components/Overlays/OverlayPanelWrapper';
import ScrollRestoration from 'components/ScrollRestoration';
import SplashScreen from 'components/SplashScreen';
import {
  darkModeSelector,
  fullContentViewStateSelector,
  sidebarCollapsedStateSelector,
} from 'features/appUI/selectors';
import {
  changeSidebarCollapsedState,
  setDarkMode,
  toggleFullContentView,
} from 'features/appUI/slice';
import { clearAuthToken } from 'features/auth/helpers';
import {
  useGetCurrentUserQuery,
  useRevokeTokenMutation,
} from 'features/auth/query';
import UpdateUserPasswordPanel from 'features/user/components/UpdateUserPasswordPanel';
import AppMainSidebar from 'layouts/MainLayout/AppMainSidebar';
import { useAppDispatch, useAppSelector } from 'lib/stores/hook';
import { Suspense, useCallback, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { history } from 'routes/history';
import { getPath } from 'routes/router-paths';
import { getAppLogo } from 'utils/getAppLogo';
import style from './MainLayout.module.scss';

const UPDATE_PASSWORD_MENU_KEY = 'userUpdatePasswordMenuItem';

const MainLayout = () => {
  history.navigate = useNavigate();
  history.location = useLocation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data: currentUser } = useGetCurrentUserQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const isSidebarCollapsed = useAppSelector(sidebarCollapsedStateSelector);
  const isFullContentView = useAppSelector(fullContentViewStateSelector);
  const isDarkMode = useAppSelector(darkModeSelector);

  const userMenuItems = useMemo(
    (): MenuProps['items'] => [
      {
        label: (
          <OverlayPanelWrapper
            renderOverlayPanel={(open, setOpen) => (
              <UpdateUserPasswordPanel
                open={open}
                setOpen={setOpen}
                selectedUser={currentUser?.data}
              />
            )}
          >
            Đổi mật khẩu
          </OverlayPanelWrapper>
        ),
        icon: <LockIcon />,
        key: UPDATE_PASSWORD_MENU_KEY,
      },
      {
        type: 'divider',
      },
      {
        label: 'Đăng xuất',
        icon: <LogoutOutlinedIcon />,
        key: 'logout',
        danger: true,
      },
    ],
    [currentUser?.data]
  );

  const [revokeTokenMutationFn] = useRevokeTokenMutation();

  const onUserMenuClick: MenuProps['onClick'] = useCallback(
    ({ key }: { key: string }) => {
      if (key === 'logout') {
        revokeTokenMutationFn(undefined);
        clearAuthToken();
        navigate(getPath('login'));
        return;
      }
      key !== UPDATE_PASSWORD_MENU_KEY && navigate(key);
    },
    [navigate, revokeTokenMutationFn]
  );

  return (
    <div className="flex h-screen">
      <aside
        className={clsx(
          style.mainSidebarWrapper,
          isSidebarCollapsed && style.collapsed,
          'dark:border-0 dark:border-r dark:border-solid dark:border-[--border-color]'
        )}
      >
        <div className="mb-10 flex items-center justify-between px-5">
          <figure
            className="mb-0 cursor-pointer"
            onClick={() => navigate(getPath('dashboard'))}
          >
            <img src={getAppLogo()} alt="" className="max-h-8 max-w-9.5" />
          </figure>
          <Dropdown
            menu={{ items: userMenuItems, onClick: onUserMenuClick }}
            placement="bottomRight"
            overlayClassName="user-menu-dropdown"
            trigger={['click']}
          >
            <div className={style.userAvatarWrapper}>
              <AccountCircleOutlinedIcon />
            </div>
          </Dropdown>
        </div>
        <div className="mb-10 flex flex-col items-center justify-center text-center">
          <img
            src={dummyAvatar}
            alt=""
            className="mb-6 block h-24 w-24 rounded-full object-cover"
          />
          <div className="flex flex-col gap-1">
            <p className="text-sm text-white">
              {currentUser?.data?.displayName ?? ''}
            </p>
            <p className="text-sm text-slate-400">
              {currentUser?.data?.email ?? ''}
            </p>
          </div>
        </div>
        <AppMainSidebar />
      </aside>

      <div
        className={clsx(
          style.mainPageContentWrapper,
          isSidebarCollapsed && style.collapsed
        )}
      >
        <header
          className={clsx(
            style.fixedHeader,
            'dark:border-b-[--border-color] dark:bg-accent'
          )}
        >
          <Tooltip title={`${isSidebarCollapsed ? 'Hiển thị' : 'Ẩn'} sidebar`}>
            <IconButton
              onClick={() =>
                dispatch(changeSidebarCollapsedState(!isSidebarCollapsed))
              }
              className="mr-auto"
            >
              <MenuIcon className="dark:text-icon-dark" />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={`${isFullContentView ? 'Thu hẹp' : 'Mở rộng'} hiển thị nội dung`}
          >
            <IconButton
              onClick={() =>
                dispatch(toggleFullContentView(!isFullContentView))
              }
            >
              {isFullContentView ? (
                <CloseFullscreenIcon className="text-xl dark:text-icon-dark" />
              ) : (
                <OpenInFullIcon className="text-xl dark:text-icon-dark" />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title={isDarkMode ? 'Bật Light mode' : 'Bật Dark mode'}>
            <IconButton onClick={() => dispatch(setDarkMode(!isDarkMode))}>
              {isDarkMode ? (
                <DarkModeIcon className="text-2xl dark:text-icon-dark" />
              ) : (
                <LightModeIcon className="text-2xl dark:text-icon-dark" />
              )}
            </IconButton>
          </Tooltip>
        </header>
        <section
          className={clsx(style.pageContentWrapper, 'dark:bg-accent')}
          id="main-layout"
        >
          <div
            className={clsx(
              'mx-auto px-8 pb-8',
              !isFullContentView && 'max-w-[144rem]'
            )}
          >
            <Suspense
              fallback={
                <ScrollRestoration>
                  <SplashScreen fullScreen />
                </ScrollRestoration>
              }
            >
              <Outlet />
            </Suspense>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MainLayout;
