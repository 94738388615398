import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BlogState, TBlogCategory } from 'features/blog-category/type';

const initialState: BlogState = {
  selectedBlogCategory: null,
};

const blogCategorySlice = createSlice({
  name: 'blogCategory',
  initialState,
  reducers: {
    setSelectedBlogCategory(
      state,
      action: PayloadAction<TBlogCategory | null>
    ) {
      state.selectedBlogCategory = action.payload;
    },
  },
});

export const { setSelectedBlogCategory } = blogCategorySlice.actions;
export default blogCategorySlice.reducer;
