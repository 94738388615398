import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UiState } from 'features/appUI/types';
import { isHEXColor } from 'utils/dataTypes/string';

const initialState: UiState = {
  isListView: true,
  isSidebarCollapsed: false,
  selectedMenuKeys: [],
  fullContentView: false,
  appPrimaryColor: '#AB9332',
  darkMode: false,
};

const appUISlice = createSlice({
  name: 'appUI',
  initialState,
  reducers: {
    changeListView(state, action: PayloadAction<boolean>) {
      state.isListView = action.payload;
    },

    changeSidebarCollapsedState(state, action: PayloadAction<boolean>) {
      state.isSidebarCollapsed = action.payload;
      localStorage.setItem('compactSidebar', JSON.stringify(action.payload));
    },

    setSelectedMenuKeys(state, action: PayloadAction<string[]>) {
      state.selectedMenuKeys = action.payload;
    },

    toggleFullContentView(state, action: PayloadAction<boolean>) {
      state.fullContentView = action.payload;
    },

    setAppPrimaryColor(state, action: PayloadAction<string>) {
      if (!isHEXColor(action.payload)) return;
      state.appPrimaryColor = action.payload;
    },

    setDarkMode(state, action: PayloadAction<boolean>) {
      state.darkMode = action.payload;
      localStorage.setItem('darkMode', JSON.stringify(action.payload));
      !action.payload
        ? document.documentElement.classList.remove('dark')
        : document.documentElement.classList.add('dark');
    },
  },
});

export const {
  changeListView,
  changeSidebarCollapsedState,
  setSelectedMenuKeys,
  toggleFullContentView,
  setAppPrimaryColor,
  setDarkMode,
} = appUISlice.actions;
export default appUISlice.reducer;
