import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import { Menu, MenuProps } from 'antd';
import { selectedMenuKeysSelector } from 'features/appUI/selectors';
import { setSelectedMenuKeys } from 'features/appUI/slice';
import { renderSidebarLabel } from 'layouts/MainLayout/helper';
import { useAppDispatch, useAppSelector } from 'lib/stores/hook';
import type { MenuInfo } from 'rc-menu/es/interface';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPath } from 'routes/router-paths';
import {
  BOOKING_STEP,
  BOOKING_TYPE,
  GROUP_REPORT_PAGE_TYPE,
  MEMBER_REPORT_PAGE_TYPE,
  TRANSACTION_REPORT_PAGE_TYPE,
  USER_REPORT_PAGE_TYPE,
} from 'routes/types';

const AppMainSidebar = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const selectedMenuKeys = useAppSelector(selectedMenuKeysSelector);

  const items = useMemo(
    (): MenuProps['items'] => [
      {
        key: 'Quản trị nội dung',
        label: 'Nội dung Website',
        type: 'group',
        children: [
          {
            key: getPath('blogCategoriesPage'),
            label: renderSidebarLabel('Danh mục'),
            icon: <FolderCopyOutlinedIcon />,
          },
          {
            key: getPath('blogPostPage'),
            label: renderSidebarLabel('Bài viết'),
            icon: <FileCopyOutlinedIcon />,
          },
          {
            key: getPath('websiteBannerManagementPage'),
            label: renderSidebarLabel('Quản trị banner'),
            icon: <BackupTableOutlinedIcon />,
            disabled: true,
          },
        ],
      },
      {
        key: 'Chăm sóc khách hàng',
        label: 'Chăm sóc khách hàng',
        type: 'group',
        children: [
          {
            key: getPath(
              'bookingCreatePage',
              BOOKING_TYPE.AIRLINE_TICKETS,
              BOOKING_STEP.SEARCH
            ),
            label: renderSidebarLabel('Đặt đơn hàng'),
            icon: <PostAddIcon />,
          },
          {
            key: getPath('bookingInsertPage', BOOKING_TYPE.AIRLINE_TICKETS),
            label: renderSidebarLabel('Thêm đơn hàng'),
            icon: <AddToPhotosIcon />,
          },
          {
            key: getPath('bookingListPage', BOOKING_TYPE.AIRLINE_TICKETS),
            label: renderSidebarLabel('Danh sách đơn hàng'),
            icon: <ContentPasteOutlinedIcon />,
          },
        ],
      },
      {
        key: 'Sales',
        label: 'Sales',
        type: 'group',
        children: [
          {
            key: getPath('groupsManagementPage'),
            label: renderSidebarLabel('Quản lý nhóm'),
            icon: <GroupOutlinedIcon />,
          },
          {
            key: getPath('membersManagementPage'),
            label: renderSidebarLabel('Quản lý thành viên'),
            icon: <SupervisedUserCircleOutlinedIcon />,
          },
          {
            key: getPath('promotionCodesManagementPage'),
            label: renderSidebarLabel('Quản lý mã ưu đãi'),
            icon: <LocalActivityOutlinedIcon />,
            disabled: !import.meta.env?.VITE_APP_IS_DEVELOPMENT,
          },
          {
            key: getPath('sendEmailPage'),
            label: renderSidebarLabel('Gửi email'),
            icon: <EmailOutlinedIcon />,
            disabled: true,
          },
        ],
      },
      {
        key: 'Kế toán',
        label: 'Kế toán',
        type: 'group',
        children: [
          {
            key: getPath('bookingReportPage', BOOKING_TYPE.AIRLINE_TICKETS),
            label: renderSidebarLabel('Danh sách đơn hàng'),
            icon: <ReceiptLongOutlinedIcon />,
          },
          {
            key: getPath('memberReportPage', MEMBER_REPORT_PAGE_TYPE.DEPOSIT),
            label: renderSidebarLabel('Kiểm toán thành viên'),
            icon: <WorkspacesOutlinedIcon />,
          },
          {
            key: getPath('userReportPage', USER_REPORT_PAGE_TYPE.CREDIT),
            label: renderSidebarLabel('Kiểm toán nhân viên'),
            icon: <PeopleAltOutlinedIcon />,
          },
          {
            key: getPath('groupReportPage', GROUP_REPORT_PAGE_TYPE.DEPOSIT),
            label: renderSidebarLabel('Kiểm toán nhóm'),
            icon: <GroupsOutlinedIcon />,
          },
          {
            key: getPath(
              'paymentGatewayReportPage',
              TRANSACTION_REPORT_PAGE_TYPE.GATEWAY
            ),
            label: renderSidebarLabel('Kiểm toán giao dịch'),
            icon: <PaymentOutlinedIcon />,
          },
          {
            key: getPath(
              'issuingHistoryReportPage',
              BOOKING_TYPE.AIRLINE_TICKETS
            ),
            label: renderSidebarLabel('Lịch sử thanh toán'),
            icon: <ManageSearchOutlinedIcon />,
          },
          {
            key: getPath('invoiceManagementPage'),
            label: renderSidebarLabel('Hoá đơn'),
            icon: <ReceiptOutlinedIcon />,
          },
        ],
      },
      {
        key: 'Quản trị',
        label: 'Quản trị',
        type: 'group',
        children: [
          {
            key: getPath('userAccountsManagementPage'),
            label: renderSidebarLabel('Tài khoản nhân viên'),
            icon: <PaymentsOutlinedIcon />,
          },
          {
            key: getPath(
              'serviceFeeManagementPage',
              BOOKING_TYPE.AIRLINE_TICKETS
            ),
            label: renderSidebarLabel('Quản lý phí dịch vụ'),
            icon: <PaidOutlinedIcon />,
          },
          {
            key: getPath(
              'pointConversionManagementPage',
              BOOKING_TYPE.AIRLINE_TICKETS
            ),
            label: renderSidebarLabel('Chính sách điểm thường'),
            icon: <MoneyOutlinedIcon />,
          },
        ],
      },
    ],
    []
  );

  const handleClickMenuItem = useCallback(
    (info: MenuInfo) => {
      const { key: navigationPath } = info;
      dispatch(setSelectedMenuKeys([navigationPath]));
      navigate(navigationPath);
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    dispatch(setSelectedMenuKeys([location.pathname]));
  }, [dispatch, location.pathname]);

  return (
    <Menu
      mode="inline"
      items={items}
      theme="dark"
      onClick={handleClickMenuItem}
      selectedKeys={selectedMenuKeys}
    />
  );
};

export default memo(AppMainSidebar);
