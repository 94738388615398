import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BookingManagementState } from 'features/booking-management/common/types';
import { TRetrievedPNRInfo } from 'features/booking/airline-tickets/types';

const initialState: BookingManagementState = {
  showExtraInfoInputForm: false,
  manualInformationInputType: false,
  retrievedPNRInfo: null,
};

const bookingManagementSlice = createSlice({
  name: 'bookingManagement',
  initialState,
  reducers: {
    setShowExtraInfoInputForm(state, action: PayloadAction<boolean>) {
      state.showExtraInfoInputForm = action.payload;
    },

    setManualInformationInputType(state, action: PayloadAction<boolean>) {
      state.manualInformationInputType = action.payload;
    },

    setRetrievedPNRInfo(
      state,
      action: PayloadAction<TRetrievedPNRInfo | null>
    ) {
      state.retrievedPNRInfo = action.payload;
    },
  },
});

export const {
  setShowExtraInfoInputForm,
  setManualInformationInputType,
  setRetrievedPNRInfo,
} = bookingManagementSlice.actions;
export default bookingManagementSlice.reducer;
