export const QueryTags = {
  AirlinePointConversion: 'AirlinePointConversion',
  AirlineServiceFees: 'AirlineServiceFees',
  AmusementPointConversion: 'AmusementPointConversion',
  BlogCategories: 'BlogCategories',
  BlogPosts: 'BlogPosts',
  BookingDetail: 'BookingDetail',
  BookingList: 'BookingList',
  GroupDeposit: 'GroupDeposit',
  GroupTransaction: 'GroupTransaction',
  HotelPointConversion: 'HotelPointConversion',
  HotelServiceFees: 'HotelServiceFees',
  InsurancePointConversion: 'InsurancePointConversion',
  InvoiceList: 'InvoiceList',
  MemberDeposit: 'MemberDeposit',
  MemberGroupDetail: 'MemberGroupDetail',
  MemberGroups: 'MemberGroups',
  MemberTransactions: 'MemberTransactions',
  SalesMembers: 'SalesMembers',
  UserAccounts: 'UserAccounts',
  UserCreditLimit: 'UserCreditLimit',
  UserDetail: 'User',
  UserTransactions: 'UserTransactions',
};
