import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'lib/axios/baseAxios';
import { baseApiEndpoint } from 'lib/constants';

export const baseApi = createApi({
  reducerPath: 'baseApi',
  // @ts-ignore
  baseQuery: axiosBaseQuery({
    baseUrl: baseApiEndpoint,
  }),
  endpoints: () => ({}),
  keepUnusedDataFor: 10,
});
