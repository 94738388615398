import { TOKEN } from 'features/auth/constants';
import { clearAuthToken, getAuthToken } from 'features/auth/helpers';
import { Navigate, useLocation } from 'react-router-dom';
import { getPath } from 'routes/router-paths';

type Props = {
  children: any;
};

const PrivateRoute = ({ children }: Props) => {
  const location = useLocation();

  if (!getAuthToken(TOKEN.REFRESH_TOKEN)) {
    clearAuthToken();
    return (
      <Navigate
        to={getPath('login')}
        state={{ redirectedFrom: location.pathname, logout: true }}
      />
    );
  }

  return children;
};

export default PrivateRoute;
