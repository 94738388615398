import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AmusementBookingState,
  TAmusementTicket,
  TAmusementTicketsSearchResult,
} from 'features/booking/amusement-tickets/types';

const initialState: AmusementBookingState = {
  searchAmusementTicketsResult: null,
  isInitSearchAmusementTickets: false,
  isSearchingAmusementTickets: false,
  selectedAmusementTicket: null,
};

const amusementTicketsBookingSlice = createSlice({
  name: 'amusementTicketsBooking',
  initialState,
  reducers: {
    setAmusementTicketsSearchResult: (
      state,
      action: PayloadAction<TAmusementTicketsSearchResult | null>
    ) => {
      if (action.payload) {
        state.searchAmusementTicketsResult = {
          ...action.payload,
          tickets: action.payload.tickets.map(ticket => ({
            ...ticket,
            rates: ticket.rates.map((rate, index) => ({
              ...rate,
              selectedQuantity: index === 0 ? 1 : 0,
            })),
          })),
        };
        return;
      }
      state.searchAmusementTicketsResult = action.payload;
    },

    setInitSearchAmusementTicketsSessionLoadingState: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isInitSearchAmusementTickets = action.payload;
    },

    setSearchAmusementTicketsLoadingState: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isSearchingAmusementTickets = action.payload;
    },

    setSelectedAmusementTicket: (
      state,
      action: PayloadAction<TAmusementTicket | null>
    ) => {
      state.selectedAmusementTicket = action.payload;
    },
  },
});

export const {
  setAmusementTicketsSearchResult,
  setInitSearchAmusementTicketsSessionLoadingState,
  setSearchAmusementTicketsLoadingState,
  setSelectedAmusementTicket,
} = amusementTicketsBookingSlice.actions;
export default amusementTicketsBookingSlice.reducer;
