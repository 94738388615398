import { Rule } from 'antd/es/form';
import { Regex, RegexValidationMessage } from 'utils/validations';

export enum FORM_FIELD {
  USERNAME = 'username',
  NEW_PASSWORD = 'newPassword',
  NEW_PASSWORD_CONFIRM = 'newPasswordConfirm',
}

export const FormLabel: Record<FORM_FIELD, string> = Object.freeze({
  [FORM_FIELD.USERNAME]: 'Tên tài khoản',
  [FORM_FIELD.NEW_PASSWORD]: 'Mật khẩu mới',
  [FORM_FIELD.NEW_PASSWORD_CONFIRM]: 'Xác nhận mật khẩu mới',
});

export const FormValidation: Record<FORM_FIELD, Rule[]> = Object.freeze({
  [FORM_FIELD.USERNAME]: [],
  [FORM_FIELD.NEW_PASSWORD]: [
    { required: true },
    {
      pattern: Regex.PASSWORD,
      message: RegexValidationMessage.PASSWORD,
    },
  ],
  [FORM_FIELD.NEW_PASSWORD_CONFIRM]: [
    { required: true },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue(FORM_FIELD.NEW_PASSWORD) === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Mật khẩu không trùng khớp'));
      },
    }),
  ],
});
