import { Button } from '@mui/material';
import clsx from 'clsx';
import { useNavigate, useRouteError } from 'react-router-dom';
import { getPath } from 'routes/router-paths';

const ErrorBoundary = () => {
  const error = useRouteError();
  const isDevMode = import.meta.env.MODE === 'development';

  const navigate = useNavigate();

  return (
    <div className="flex h-full w-full flex-col items-center justify-center py-10">
      <h3 className="h3 mb-1 text-3xl font-bold">Có lỗi xảy ra!</h3>
      <p className="mb-6 text-base">Vui lòng thử lại hoặc quay về trang chủ</p>
      {isDevMode && (
        <div
          className={clsx(
            'mb-6 max-w-[64rem] p-6',
            'bg-error-lighter text-error',
            'border border-solid border-error',
            'break-words text-sm',
            'max-h-50 overflow-y-auto overflow-x-hidden rounded-xl'
          )}
        >
          {/* @ts-ignore */}
          {error.stack}
        </div>
      )}
      <div className="jusitfy-center flex items-center gap-x-4">
        <Button
          variant="outlined"
          onClick={() => navigate(getPath('dashboard'))}
        >
          Quay lại trang chủ
        </Button>
        <Button onClick={() => window.location.reload()}>Tải lại trang</Button>
      </div>
    </div>
  );
};

export default ErrorBoundary;
