import {
  BOOKING_STEP,
  BOOKING_TYPE,
  GROUP_REPORT_PAGE_TYPE,
  MEMBER_REPORT_PAGE_TYPE,
  TRANSACTION_REPORT_PAGE_TYPE,
  USER_REPORT_PAGE_TYPE,
} from 'routes/types';

const publicPaths = {
  login: () => '/auth/login',
};

const privatePaths = {
  dashboard: () => '/',
  /** Website content manager */
  blogCategoriesPage: () => '/content-manager/blog/categories',
  blogPostPage: (query?: string) =>
    `/content-manager/blog/posts${query ? `?${query}` : ''}`,
  addEditBlogPostPage: (postId?: string) =>
    `/content-manager/blog/posts/${postId ?? 'add'}`,
  websiteBannerManagementPage: () => `/content-manager/web/banner-management`,

  /** Booking */
  bookingCreatePage: (
    bookingType: BOOKING_TYPE,
    bookingStep: BOOKING_STEP,
    query?: string
  ) =>
    `/booking/create/${bookingType}/${bookingStep}${query ? `?${query}` : ''}`,
  bookingListPage: (bookingType: BOOKING_TYPE, query?: string) =>
    `/booking/list/${bookingType}${query ? `?${query}` : ''}`,
  bookingInsertPage: (bookingType: BOOKING_TYPE) =>
    `/booking/insert/${bookingType}`,

  /** Sales */
  groupsManagementPage: (query?: string) =>
    `/sales/groups${query ? `?${query}` : ''}`,
  groupDetailPage: (groupId: string) => `/sales/groups/${groupId}`,
  membersManagementPage: (query?: string) =>
    `/sales/members${query ? `?${query}` : ''}`,
  promotionCodesManagementPage: (query?: string) =>
    `/sales/promotion${query ? `?${query}` : ''}`,
  promotionCodeDetailPage: (promotionCodeId: string) =>
    `/sales/promotion/${promotionCodeId}`,
  sendEmailPage: () => `/sales/send-email`,

  /** Accounting */
  bookingReportPage: (bookingType: BOOKING_TYPE, query?: string) =>
    `/accounting/booking/list/${bookingType}${query ? `?${query}` : ''}`,
  memberReportPage: (reportType: MEMBER_REPORT_PAGE_TYPE, query?: string) =>
    `/accounting/member/${reportType}${query ? `?${query}` : ''}`,
  userReportPage: (reportType: USER_REPORT_PAGE_TYPE, query?: string) =>
    `/accounting/user/${reportType}${query ? `?${query}` : ''}`,
  groupReportPage: (reportType: GROUP_REPORT_PAGE_TYPE, query?: string) =>
    `/accounting/group/${reportType}${query ? `?${query}` : ''}`,
  paymentGatewayReportPage: (
    reportType: TRANSACTION_REPORT_PAGE_TYPE,
    query?: string
  ) => `/accounting/transaction/${reportType}${query ? `?${query}` : ''}`,
  issuingHistoryReportPage: (reportType: BOOKING_TYPE, query?: string) =>
    `/accounting/issuing-history/${reportType}${query ? `?${query}` : ''}`,
  invoiceManagementPage: (query?: string) =>
    `/accounting/invoice${query ? `?${query}` : ''}`,

  /** Management */
  userAccountsManagementPage: (query?: string) =>
    `/management/user-accounts${query ? `?${query}` : ''}`,
  serviceFeeManagementPage: (reportType: BOOKING_TYPE, query?: string) =>
    `/management/service-fee/${reportType}${query ? `?${query}` : ''}`,
  pointConversionManagementPage: (reportType: BOOKING_TYPE, query?: string) =>
    `/management/point-conversion/${reportType}${query ? `?${query}` : ''}`,
};

const pathsMap = {
  ...publicPaths,
  ...privatePaths,
};

export type PathsMap = typeof pathsMap;

export const getPath = <TRoute extends keyof PathsMap>(
  route: TRoute,
  ...params: Parameters<PathsMap[TRoute]>
) => {
  const pathCb: (...args: any[]) => string = pathsMap[route];
  return pathCb(...params);
};
