import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AirlineTicketsBookingState,
  TBaggagesTotalPrice,
  TFlightBookingInProgress,
  TSearchFlightResult,
  TSelectedFlight,
} from 'features/booking/airline-tickets/types';

const initialState: AirlineTicketsBookingState = {
  searchFlightResult: null,
  selectedFlights: [],
  isInitSearchFlightSession: false,
  isSearchingFlights: false,
  flightBookingInProgressDetail: null,
  baggageTotalPrice: {
    departureBaggage: 0,
    returnBaggage: 0,
  },
};

const airlineTicketsBookingSlice = createSlice({
  name: 'airlineTicketsBooking',
  initialState,
  reducers: {
    setSearchFlightResult: (
      state,
      action: PayloadAction<TSearchFlightResult | null>
    ) => {
      state.searchFlightResult = action.payload;
    },

    setSelectedFlights: (state, action: PayloadAction<TSelectedFlight[]>) => {
      state.selectedFlights = action.payload;
    },

    setInitSearchFlightsSessionLoadingState: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isInitSearchFlightSession = action.payload;
    },

    setSearchFlightLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isSearchingFlights = action.payload;
    },

    setFlightBookingInProgressDetail: (
      state,
      action: PayloadAction<TFlightBookingInProgress | null>
    ) => {
      state.flightBookingInProgressDetail = action.payload;
    },

    setBaggageTotalPrice: (
      state,
      action: PayloadAction<TBaggagesTotalPrice>
    ) => {
      state.baggageTotalPrice = action.payload;
    },
  },
});

export const {
  setSearchFlightResult,
  setSelectedFlights,
  setSearchFlightLoadingState,
  setInitSearchFlightsSessionLoadingState,
  setFlightBookingInProgressDetail,
  setBaggageTotalPrice,
} = airlineTicketsBookingSlice.actions;
export default airlineTicketsBookingSlice.reducer;
