export const trimmedSpacesKeyword = (keyword?: string) => {
  if (!keyword) return '';
  return keyword.trim().replace(/ +(?= )/g, '');
};

export const isHEXColor = (color: string) => {
  return /^#([A-Fa-f0-9]{3}){1,2}$/.test(color);
};

export const hexToRgba = (hex: string, alpha: number): string => {
  if (!isHEXColor) {
    throw new Error('Invalid hex color code');
  }

  let c: string | number[] | (string | number)[] = hex.substring(1).split('');
  if (c.length === 3) {
    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
  }
  c = '0x' + (c as number[]).join('');

  const r = (parseInt(c as string, 16) >> 16) & 255;
  const g = (parseInt(c as string, 16) >> 8) & 255;
  const b = parseInt(c as string, 16) & 255;

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const convertHexToRgbaPair = (
  hex: string
): Record<'solid' | 'transparent', string> => {
  const hexColorRegex = /^#([0-9A-Fa-f]{3}){1,2}([0-9A-Fa-f]{2})?$/;
  if (!hexColorRegex.test(hex)) {
    throw new Error('Invalid hex color.');
  }
  hex = hex.replace(/^#/, '');
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return {
    solid: `rgba(${r}, ${g}, ${b}, 1)`,
    transparent: `rgba(${r}, ${g}, ${b}, 0)`,
  };
};

export const removeVietnameseTones = (str: string) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '');
  str = str.replace(/\u02C6|\u0306|\u031B/g, '');
  str = str.replace(/ + /g, ' ');
  str = str.trim();
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ' '
  );
  return str;
};

export const formatDisplayCurrency = (
  price: number,
  currency = 'VND'
): string => {
  const config = {
    style: 'currency',
    currency: currency || 'VND',
  };

  let locale: string;

  switch (currency) {
    case 'USD':
      locale = 'en-US';
      break;
    default:
      locale = 'vi-VN';
  }

  return price !== undefined
    ? new Intl.NumberFormat(locale, config).format(price)
    : '-';
};
